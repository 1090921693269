import React from "react";
import { App } from "./app";
import { Auth0ProviderWithHistory } from "./auth0-provider-with-history";
import { EnvProvider } from "./context/env.context";
import "./styles/styles.css";
import { createRoot } from "react-dom/client";
import { HashRouter } from "react-router-dom";

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <HashRouter>
        <EnvProvider>
          <Auth0ProviderWithHistory>
            <App />
          </Auth0ProviderWithHistory>
        </EnvProvider>
      </HashRouter>
    </React.StrictMode>
  );
}
