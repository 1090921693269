import React from 'react';
import { CompanyNameField } from "../CompanyNameField";
import { TextView } from "../../../components/TextView";
import { FreeUsersField } from "../FreeUsersField";
import { PaidUsersField } from "../PaidUsersField";
import { NumberOfTeamsField } from "../NumberOfTeamsField";
import { NumberOfMeetingRoomsField } from "../NumberOfMeetingRoomsField";
import { CarParkBookingField } from "../CarParkBookingField";
import { Switch } from "../../../components/Switch";
import { CompanyEntity } from "../../../models/company.model";

export function CompanyAccountDetails(props: Props) {
  const {company} = props;
  return (
    <div>
      <CompanyNameField company={company} />

      <TextView label="Company Id"
                disabled={true} wrapperStyle={{marginBottom: 16}}
                value={company?.id ?? '??'}
                clickToCopy={true}
                onChange={() => {}}/>

      <FreeUsersField company={company} />

      <PaidUsersField company={company} />

      <NumberOfTeamsField company={company} />

      <NumberOfMeetingRoomsField company={company} />

      <CarParkBookingField company={company} />

      <div className={"container__domainName"}>
        <p className={'body__xsmall body--bold'}>Domain based signup</p>
        <div className={"domainName__controls"}>
          <Switch value={company.domainNameSignupsEnabled && !company.domainNameSignupPending}
                  disabled={true} />
          <TextView placeholder={"Domain name"}
                    value={company?.domainName}
                    disabled={true}
                    onChange={val => val} />
        </div>
      </div>
    </div>
  )
}

interface Props {
  company: CompanyEntity;
}
