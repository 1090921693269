import React, { useMemo } from 'react';

export enum Size {
  VerySmall = 'verySmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}
export enum Variant {
  Primary = 'primary',
  Outline = 'outline',
  OutlineSelected = 'outlineSelected',
}

export function Button(props: Props) {
  const classNames = useMemo(() => {
    let retVal = 'button ';
    retVal += `button--${props.size ?? Size.Medium} `;
    retVal += `button--${props.variant ?? Variant.Primary} `;
    if (props.disabled) retVal += `button--disabled `;
    return retVal.trim();
  }, [props]);

  const onClick = () => {
    if (props.disabled) return;
    if (props.onClick) props.onClick();
  };

  return (
    <button className={classNames} onClick={onClick} style={props?.style ?? {}}>
      {props.text && <p className={"body__small"}>{props.text}</p>}
    </button>
  );
}

interface Props {
  text?: string;
  size?: Size;
  disabled?: boolean;
  variant?: Variant;
  style?: any;
  onClick?: () => any;
}
