import React, { useEffect, useState } from 'react';
import { AccountType, CompanyEntity } from "../../models/company.model";
import { SimpleSpinner } from "../../components/SimpleSpinner";
import { useHttpRequest } from "../../hooks/use-http-request";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export function AccountTypeField(props: Props) {
  const {company} = props;
  const [accountType, setAccountType] = useState(AccountType.STANDARD)
  const request = useHttpRequest('/api/company/update');

  useEffect(() => {
    if (company?.accountType) {
      setAccountType(company.accountType);
    }
  }, [company]);

  const onChange = async (val: any) => {
    if (company) {
      request.PUT({
        key: 'ACCOUNT_TYPE',
        value: val.target.value || null,
        companyId: company.id,
      })
      setAccountType(val);
    }
  }
  return (
    <div className={"company__adminSettingsRow"}>
      <p className={"body__regular"}>Account type</p>
      <div className={"updateableField__wrapper"}>
        <FormControl fullWidth>
          <InputLabel id="account-type-label">Account type</InputLabel>
          <Select
            labelId="account-type-label"
            id="account-type"
            disabled={request.loading}
            value={accountType}
            label="Account type"
            onChange={onChange}
          >
            <MenuItem value={AccountType.STANDARD} selected={!accountType || accountType === AccountType.STANDARD}>{AccountType.STANDARD}</MenuItem>
            <MenuItem value={AccountType.FREE_TRIAL} selected={accountType === AccountType.FREE_TRIAL}>{AccountType.FREE_TRIAL}</MenuItem>
            <MenuItem value={AccountType.PROFESSIONAL} selected={accountType === AccountType.PROFESSIONAL}>{AccountType.PROFESSIONAL}</MenuItem>
            <MenuItem value={AccountType.PREMIUM} selected={accountType === AccountType.PREMIUM}>{AccountType.PREMIUM}</MenuItem>
          </Select>
        </FormControl>
        {request.loading && <SimpleSpinner />}
      </div>
    </div>

  )
}

interface Props {
  company?: CompanyEntity;
}
