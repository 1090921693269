import React, { useState } from 'react';
import { Dialog, TextField } from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { CompanyEntity } from "../../models/company.model";
import { setupFreeTrial } from "../../api/user-requests";
import { format } from "date-fns";


export function FreeTrialDialog(props: Props) {
  const [date, setDate] = useState<any>(null);
  const [numberOfUsers, setNumberOfUsers] = useState<any>('0');
  const [loading, setLoading] = useState(false);
  const done = async () => {
    try{
      setLoading(true);
      await setupFreeTrial(props.company.id, date, parseInt(numberOfUsers));
    } finally {
      setLoading(false);
      props.onClose();
    }
  }
  return (
    <Dialog open={props.isOpen}>
      <div className="dialogContent">
        <h3>Free trial</h3>
        {props.company.paidForUsers > 0 && props.company.enabled ? <>
          <p>This account is already a paid account</p>
          <button className="button button--secondary"
                  disabled={loading}
                  onClick={props.onClose} >
            Close
          </button>
        </> : <>
          <TextField label="Number of users"
                     className="dialog__field"
                     disabled={loading}
                     variant="outlined"
                     value={numberOfUsers}
                     onChange={(e) => setNumberOfUsers(e.target.value)} />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Trial Expiry Date"
              className="dialog__field"
              disabled={loading}
              value={date}
              onChange={(newValue: any) => {
                setDate(format(newValue, 'yyyy-MM-dd'));
              }}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <div className="dialog__controls">
            <button className="button button--primary"
                    disabled={loading}
                    onClick={() => done()} >
              Set up
            </button>
            <button className="button button--secondary"
                    disabled={loading}
                    onClick={props.onClose} >
              Close
            </button>
          </div>
        </>}
      </div>
    </Dialog>
  )
}

interface Props {
  isOpen: boolean;
  onClose: any;
  company: CompanyEntity;
}
