import React from 'react';
import { NavLink } from "react-router-dom";

export function ReportsPage(props: Props) {
  return (
    <div className={'report__page'}>
      <NavLink to={'/reports/company-usage'} exact>
        <div className={'report__link'}>
          <p>Company usage</p>
        </div>
      </NavLink>
    </div>
  )
}

interface Props {
}
