import React, { useEffect, useMemo } from 'react';
import { useHttpRequest } from "../../hooks/use-http-request";
import { SortableCompanyUsageTable } from "./SortableCompanyUsageTable";
import { ViewCompanyButton } from "../company-list/components/ViewCompanyButton";

export function CompanyUsageReport(props: Props) {
  const http = useHttpRequest('/api/reports/account-usage');

  useEffect(() => {
    if (!http.loading && !http.response) {
      http.GET();
    }
  }, [http]);

  const tableColumns = useMemo(() => {
    return [
      {Header: 'Company Name', accessor: 'name', isSorted: false },
      {Header: 'Free Users', accessor: 'freeUsers', isSorted: false },
      {Header: 'Paid Users', accessor: 'paidForUsers', isSorted: false },
      {Header: 'User Limit', accessor: 'userLimit', isSorted: false },
      {Header: 'Active Users', accessor: 'totalUsers', isSorted: true },
      {Header: 'Remaining', accessor: 'remaining', isSorted: true },
      {
        Header: () => null,
        id: 'viewCompanyAccountUsageReport',
        Cell: (props: any) => (
          <ViewCompanyButton {...props} />
        ),
      }
    ];
  }, []);

  const contentData = useMemo(() => {
    if (http.response) {
      return http.response.map((row: any) => ({...row, remaining: row.userLimit - row.totalUsers}));
    }
    return [];
  }, [http.response]);

  return (
    <div>
      <h4>Showing all companies with more than 2 users</h4>
      <SortableCompanyUsageTable columns={tableColumns} data={contentData} />
    </div>
  )
}

interface Props {
}
