import React from "react";
import { NavLink } from "react-router-dom";
import { AuthenticationButton } from "./buttons/authentication-button";
import logo from '../images/team-today-logo.svg';

export const NavBar: React.FC = () => {
  return (
    <div className="nav-bar__container">
      <nav className="nav-bar">
        <div className="nav-bar__brand">
          <NavLink to="/">
            <img src={logo} alt={"team today logo"} />
          </NavLink>
        </div>
        <div className="nav-bar__tabs">
          <NavLink
            to="/reports"
            exact
            className="nav-bar__tab"
            activeClassName="nav-bar__tab--active"
          >
            Reports
          </NavLink>
          <NavLink
            to="/users"
            exact
            className="nav-bar__tab"
            activeClassName="nav-bar__tab--active"
          >
            Users
          </NavLink>
          <NavLink
            to="/companies"
            exact
            className="nav-bar__tab"
            activeClassName="nav-bar__tab--active"
          >
            Companies
          </NavLink>
        </div>
        <div className="nav-bar__actions">
          <AuthenticationButton />
        </div>
      </nav>
    </div>
  );
};
