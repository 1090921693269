import React, { useEffect } from 'react';
import { CompanyEntity } from "../../../models/company.model";
import { PaymentTypeField } from "../PaymentTypeField";
import { RenewalDateField } from "../RenewalDateField";
import { useHttpRequest } from "../../../hooks/use-http-request";
import { StripeStateField } from "../components/StripeStateField";
import { StripeCustomerIdField } from "../components/StripeCustomerIdField";
import { StripeSubscriptionIdField } from "../components/StripeSubscriptionIdField";
import { AccountTypeField } from "../AccountTypeField";

export function CompanySubscriptionDetails(props: Props) {
  const {company} = props;
  const stripeSubscription = useHttpRequest(`/company-stripe/${company.id}`);

  useEffect(() => {
    if (!stripeSubscription.loading && !stripeSubscription.response) {
      stripeSubscription.GET();
    }
  }, [stripeSubscription]);

  return (
    <div>
      <div className={"company__adminSettings"}>
        <h3>Admin Account Settings</h3>
        <AccountTypeField company={company} />
        <PaymentTypeField company={company} />
        <RenewalDateField company={company} />

        <h3>Stripe Details</h3>

        {stripeSubscription.response && !stripeSubscription.loading && <>
          <StripeCustomerIdField stripeSubscription={stripeSubscription.response} />
          <StripeSubscriptionIdField stripeSubscription={stripeSubscription.response} />
          <StripeStateField stripeSubscription={stripeSubscription.response} />
        </>}

      </div>
    </div>
  )
}

interface Props {
  company: CompanyEntity
}
