import React, { useEffect, useState } from 'react';
import { getUsersInCompany } from "../../api/user-requests";
import { User } from "../../models/user.model";
import { UserButton } from "../components/UserButton";
import { TeamButton } from "../components/TeamButton";
import { toast } from "react-toastify";
import { Button, Size, Variant } from "../../components/buttons/Button";

export function UsersInCompanyList(props: Props) {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    getUsersInCompany(props.companyId).then(setUsers);
  }, [props]);

  const onClickToCopy = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      toast("Copied to clipboard!", {toastId: 'copied'});
    })
  }

  return (
    <div>
      <h3>Users</h3>
      <div className="userList__container">
        <div className="userList__header">
          <span className="userList__" style={{flex: 2}}>Email</span>
          <span className="userList__" style={{flex: 1}}>Team name</span>
          <span className="userList__" style={{flex: 1}}>Auth type</span>
          <span className="userList__" style={{flex: 1}}>Role</span>
          <span className="userList__" style={{flex: 1}}>To user</span>
          <span className="userList__" style={{flex: 1}}>To team</span>
          <span className="userList__" style={{flex: 1}}></span>
        </div>
        {users.map((user: User, key: number) => <div key={key} className="userList__row">
          <span className="userList__" style={{flex: 2}} onClick={() => onClickToCopy(user.email)}>{user.email}</span>
          <span className="userList__" style={{flex: 1}}>{user.teamName ?? '??'}</span>
          <span className="userList__" style={{flex: 1}}>{user.authType ?? 'TT'}</span>
          <span className="userList__" style={{flex: 1}}>{user.role ?? '??'}</span>
          <span className="userList__" style={{flex: 1}}>
            <UserButton userId={user.id} />
          </span>
          <span className="userList__" style={{flex: 1}}>
            <TeamButton teamId={user.teamId} />
          </span>
          <span className="userList__" style={{flex: 1}}>
            <Button text={'Copy ID'} onClick={() => onClickToCopy(user.id)} variant={Variant.Outline} size={Size.VerySmall}/>
          </span>
        </div>)}
      </div>
    </div>
  )
}

interface Props {
  companyId: string;
}
