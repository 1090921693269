import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useMemo, useState } from "react";
import { getCompany } from "../../api/user-requests";
import { useParams } from "react-router-dom";
import { CompanyEntity } from "../../models/company.model";
import { Chip, Stack } from "@mui/material";
import { UsersInCompanyList } from "./UsersInCompanyList";
import { FreeTrialDialog } from "./FreeTrialDialog";
import '../../styles/pages/_company-view.scss';
import { Button, Size, Variant } from "../../components/buttons/Button";
import { useHttpRequest } from "../../hooks/use-http-request";
import { OfficeList } from "./OfficeList";
import { CompanyAccountDetails } from "./views/CompanyAccountDetails";
import { CompanySubscriptionDetails } from "./views/CompanySubscriptionDetails";

enum Views {
  Account, Users, Offices, Subscription
}

export const CompanyView = () => {
  const { user } = useAuth0();
  const params: any = useParams();
  const [company, setCompany] = useState<CompanyEntity | undefined>();
  const [isFreeTrialDialogOpen, setIsFreeTrialDialogOpen] = useState(false);
  const isPaidAccount = useMemo(() => (company?.paidForUsers ?? 0) > 0 && (company?.enabled), [company]);
  const isFreeAccount = useMemo(() => (company?.freeUsers ?? 0) > 10, [company]);
  const joinCompanyHttpRequest = useHttpRequest('/api/company/join');
  const [view, setView] = useState(Views.Account)

  useEffect(() => {
    if (params.companyId) {
      getCompany(params.companyId).then(setCompany);
    }
  }, [params]);

  const onJoinCompanyClicked = () => {
    if (!company || !user) return;
    joinCompanyHttpRequest.POST({email: user.email, companyId: company.id});
    alert(`"${user.email}" will be added to "${company.name ?? company.id}" as a company admin`);
  }

  if (!user || !company) {
    return null;
  }

  return (
    <div className="content-layout">
      {company && <FreeTrialDialog isOpen={isFreeTrialDialogOpen}
                                   company={company}
                                   onClose={() => setIsFreeTrialDialogOpen(false)} />}

      <div className={"companyView__header"}>
        <h1 className="content__title">Company view</h1>

        <div className={'company__buttonRow'}>
          {params.companyId && <a href={`/#/company/${params.companyId}/bulk-upload`}>
              <Button variant={Variant.Outline}
                      size={Size.Medium}
                      text={'Bulk uploads'}
                      onClick={() => {}} />
            </a>}
          <Button variant={Variant.Primary}
                  size={Size.Medium}
                  disabled={joinCompanyHttpRequest.loading}
                  text={'Join company'}
                  onClick={onJoinCompanyClicked} />
        </div>
      </div>

      <div className="companyQuickInfo">
        <Stack direction="row" spacing={1}>
          {isPaidAccount && <Chip label="Paid account" color="success" />}
          {(company?.freeUsers > 10) && <Chip label="Free-Trial account" color="warning" />}
          {company.accountType && <Chip label={company.accountType} color="success"/>}
          {isFreeAccount && <Chip label="Free account" color="success" />}
        </Stack>
      </div>
      <div className="companyControls">
        {!isPaidAccount && <button className="button button--primary button--compact" onClick={() => setIsFreeTrialDialogOpen(true)} >
          Set up free trial
        </button>}
      </div>

      <div className={'tabView__container'}>
        <div className={'tabView__header'}>
          <div className={'tabView__headerItem'} onClick={() => setView(Views.Account)}>
            <span>Account</span>
          </div>
          <div className={'tabView__headerItem'} onClick={() => setView(Views.Users)}>
            <span>Users</span>
          </div>
          <div className={'tabView__headerItem'} onClick={() => setView(Views.Offices)}>
            <span>Offices</span>
          </div>
          <div className={'tabView__headerItem'} onClick={() => setView(Views.Subscription)}>
            <span>Subscription</span>
          </div>
        </div>
        {view === Views.Account && <div className={'tabView__content'}>
          <CompanyAccountDetails company={company} />
        </div>}
        {view === Views.Users && <div className={'tabView__content'}>
          <UsersInCompanyList companyId={company.id} />
        </div>}
        {view === Views.Offices && <div className={'tabView__content'}>
          <OfficeList companyId={company.id} />
        </div>}
        {view === Views.Subscription && <div className={'tabView__content'}>
          <CompanySubscriptionDetails company={company} />
        </div>}
      </div>

    </div>
  );
};
